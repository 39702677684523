.edit-page-layout {
  height: calc(100vh - 5rem);
  margin-bottom: 0 !important;

  .left-column {
    box-shadow: 1px 0 0 0 $grey-lighter;
    overflow: auto;
    min-width: 25rem;
  }

  .left-content {
    &:not(:last-child) {
      border-bottom: $white-ter;
    }

    .accordion {
      box-shadow: none;
      border-radius: 0;

      .card-header-title {
        padding-top: 2rem;
        padding-left: 2rem;
      }

      .card-header-icon {
        padding-top: 2rem;
        padding-right: calc(2rem - 6px);
      }

      .accordion-content {
        + .card-header {
          border-top-color: $white-ter;
        }
      }

      .card-content {
        padding: 0 1.5rem 1rem;
      }
    }

    .events-accordion {
      .card-header-title {
        padding-left: 0;
      }

      .event-header {
        flex: 1;
      }

      .card-content {
        padding-left: 0;
        padding-right: 0;
      }

      .input {
        &.has-subhelper {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: 0;
        }

        &:hover,
        &:focus {
          ~ .subhelper {
            background-color: $ui-10;
          }
        }
      }

      .subhelper {
        background-color: $input-background-color;
        padding-left: calc(2.75rem + 1px);
        font-size: 0.8rem;
        border-bottom-left-radius: $input-radius;
        border-bottom-right-radius: $input-radius;
        display: block;
        padding-bottom: 0.25rem;
        transition: background-color $speed $easing;
        border: 1px solid $white-ter;
        border-top: 0;
      }
    }
  }

  .right-column {
    position: relative;
    overflow: auto;
    display: flex;
    flex-flow: column;

    .react-loading-skeleton {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .tabs {
      margin-bottom: 0;
      margin-left: 1px;
      flex-shrink: 0;
      position: sticky;
      top: 0;
      z-index: 2;

      ~ [class*="rich-edit-content"] {
        .ql-toolbar {
          top: 2.875rem;
        }
      }
    }

    .image-gallery {
      padding: 2rem;
    }

    .container {
      padding-top: 2rem;
      padding-bottom: 2rem;
      width: 100%;

      .table-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
