.app {
  min-height: 100vh;
  display: flex;
  flex-flow: column;

  > .columns {
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
    min-height: 100vh;
  }
}
