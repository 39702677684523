.buttons.is-crud-footer {
  margin-top: 2rem;
}

.button {
  border-radius: $radius-large;

  &.is-text {
    &.is-not-underlined {
      text-decoration: none;
    }
  }

  &.is-outlined {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 2rem;
  }

  &.button-with-icon {
    background-color: $blue;
    margin: 0px 24px;

    span {
      color: $blue-light;
      font-size: 16px;
    }
    p {
      color: $white-ter;
      text-transform: capitalize;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.pill-button {
  padding: 0px 12px;
  border-radius: 8px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0 8px;
  cursor: pointer;

  &.accept {
    background: $blue;
    color: $white-ter;
    border: none;
  }

  &.reject {
    color: $purple-light;
    background-color: $white;
    border: 1px solid $grey-light;
  }
}
