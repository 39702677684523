// Changes default Bulma colors
$purple: #423690;
$blue: #006bd6;
$green: #36d08b;
$cyan: #ae5ff3;
$red: #e35757;
$yellow: #ffbf00;
$grey-dark: #3d3d41;
$grey: #8592ad;
$grey-medium-dark: darken($grey, 20%);
$grey-light: #dcdfe5;
$grey-lighter: #e8e9ed;
$white-ter: #F4F5F6;
$white: #fff;

// Adds custom theme colors
$purple-dark: #0a255c;
$purple-light: #4b5481;
$purple-ter: #f0effb;
$blue-400: #008DDD;
$blue-light: #5ed5ed;
$blue-ter: #dae7f8;
$ui-60: #AEB8CC;
$ui-50: #cdd5e5;
$ui-30: #E8E9ED;
$ui-10: #fcfcfd;
$custom-colors: (
  'primary-dark': (
    $purple-dark,
    $white,
  ),
  'primary-light': (
    $purple-light,
    $grey-dark,
  ),
  'secondary': (
    $blue,
    $white,
  ),
  'secondary-light': (
    $blue-light,
    $grey-dark,
  ),
  'blue-400': (
    $blue-400,
    black,
  )
);
// TODO: uncomment when bulma fixes custom shades
// https://github.com/jgthms/bulma/issues/2483
// $custom-shades: (
//   'purple-dark': $purple-dark,
//   'purple-light': $purple-light,
//   'purple-ter': $purple-ter,
//   'blue-light': $blue-light,
//   'blue-ter': $blue-ter,
//   'blue-400': $blue-400,
//   'ui-60': $ui-60,
//   'grey-medium-dark': $grey-medium-dark
// );
// TODO: remove all classes below when bulma fixes custom shades
.has-text-grey-medium-dark {
  color: $grey-medium-dark !important;
}
.has-background-grey-medium-dark {
  background-color: $grey-medium-dark !important;
}
.has-text-grey-lighter {
  color: $grey-lighter !important;
}
.has-text-blue {
  color: $blue !important;
}
.has-text-blue-400 {
  color: $blue-400 !important;
}
.has-text-ui-60 {
  color: $ui-60 !important;
}
.has-text-purple-light {
  color: $purple-light !important;
}

// Primary variables
$radius-small: 4px;
$radius: 6px;
$radius-large: 8px;
$radius-rounded: 290486px;
$family-sans-serif: 'Fira Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

// Changes default derived Bulma variables
$primary: $blue;
$info: $blue;
$warning: $yellow;
$danger: $red;
$link: $blue;
$link-visited: $blue-light;
$text: $purple-dark;
$text-strong: #0a214f;

// Pagination
$pagination-color: $ui-60;
$pagination-border-color: transparent;
$pagination-hover-color: $primary;
$pagination-hover-border-color: transparent;
$pagination-focus-color: $primary;
$pagination-focus-border-color: transparent;
$pagination-active-color: $primary;
$pagination-active-border-color: transparent;
$pagination-current-color: $primary;
$pagination-current-background-color: transparent;
$pagination-current-border-color: transparent;
$pagination-disabled-background-color: transparent;
$pagination-disabled-border-color: transparent;
$pagination-min-width: 3rem;
$pagination-item-margin: 0;

// Dropdowns
$dropdown-content-shadow: 0px 8px 16px -4px rgba(10, 10, 92, 0.08), 0px 0px 2px rgba(10, 10, 92, 0.08);;
$dropdown-content-padding-top: 0.25rem;
$dropdown-content-padding-bottom: 0.25rem;
$dropdown-item-color: $grey;
$dropdown-item-hover-color: $primary;
$dropdown-item-hover-background-color: transparent;
$dropdown-item-active-background-color: $primary;
$dropdown-divider-background-color: $white-ter;

// Cards
$card-shadow: 0px 4px 6px -4px rgba(10, 10, 92, 0.08), 0px 1px 3px rgba(10, 10, 92, 0.08);
$card-content-padding: 1.5rem;

// Tabs
$tabs-border-bottom-color: rgba(black, 0.08);
$tabs-link-color: $grey;
$tabs-link-hover-border-bottom-color: $primary;
$tabs-link-hover-color: $primary;
$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $blue;
$tabs-link-padding: 0.75rem 1rem calc(0.75rem - 2px);
// Custom
$tabs-negative-margin: ($card-content-padding * -1);

// Inputs
$control-padding-horizontal: calc(0.75rem - 1px);
$control-padding-vertical: calc(1.2rem - 1px);
$label-color: $grey;
$input-border-color: $white-ter;
$input-shadow: 0 0 0 transparent;
$input-radius: $radius-large;
$input-hover-color: $purple-light;
$input-hover-border-color: $white-ter;
$input-focus-border-color: $white-ter;
$input-icon-active-color: $blue-400;
$input-color: $purple-dark;
$input-background-color: $white-ter;
$input-icon-color: $primary;

// Modal
$modal-card-foot-radius: $radius;
$modal-card-head-radius: $radius;
$modal-card-title-color: $grey;
$modal-card-title-size: 1.25rem; // $size-5
$modal-card-head-background-color: $white;
$modal-card-body-padding: 40px;
$modal-card-spacing: 150px;
// Custom
$modal-card-shadow: 20px 40px 60px rgba(black, 0.1);

// Breadcrumb
$breadcrumb-item-color: $purple-dark;
$breadcrumb-item-hover-color: $purple;
$breadcrumb-item-active-color: $blue;
$breadcrumb-item-separator-color: $grey-lighter;

// Title
$subtitle-color: $purple-light;
$subtitle-weight: 700;

// Box
$box-background-color: $white-ter;
$box-shadow: none;

// HR
$hr-background-color: $grey-light;

// Navbar
$navbar-padding-vertical: 0.75rem;
$navbar-padding-horizontal: 1.5rem;

// Table
$table-color: $grey;
$table-cell-border: 1px solid rgba(black, 0.08);
$table-cell-padding: 0.25rem 0.625rem;
$table-head-cell-border-width: 0 0 1px;
$table-cell-heading-color: $grey;
$table-head-cell-color: $grey;

/**
  Custom
*/

// Gradients
$gradient-bright-start: $blue-light;
$gradient-bright-end: #918eff;
$gradient-bright-default: linear-gradient(to top right, $gradient-bright-start, $gradient-bright-end);
$gradient-disabled-start: #dbe3ee;
$gradient-disabled-end: #cfcfe0;
$gradient-disabled-default: linear-gradient(to top right, $gradient-disabled-start, $gradient-disabled-end);
$gradient-dark-start: $purple;
$gradient-dark-end: #19105f;
$gradient-dark-default: linear-gradient(to bottom, $gradient-dark-start, $gradient-dark-end);
$gradient-warning-start: #ef2d56;
$gradient-warning-middle: #918eff;
$gradient-warning-end: #619ef3;
$gradient-warning-default: linear-gradient(
  to top right,
  $gradient-warning-start,
  $gradient-warning-middle 80%,
  $gradient-warning-end
);

// Field
$label-font-size: 15px;
$label-font-size-active: 0.6875rem;
$label-position-top: 0.75rem;
$label-position-left: 0.75rem;
$input-font-size: 1rem;
$input-height: 3rem;

// Side Nav
$sidebar-width: 256px;
$sidebar-wide-width: 480px;
$sidebar-menu-item-background: $text-strong;

// Page settings
// This number is combined from paddings of content without sidebar
$page-spacing: 158px;
