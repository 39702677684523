.field.has-floating-label {
  position: relative;

  .label {
    position: absolute;
    z-index: 1;
    top: $label-position-top;
    left: $label-position-left;
    font-size: $label-font-size;
    margin: 0;
    transition: all $easing $speed;
    pointer-events: none;
    font-weight: 400;
    line-height: 1.5rem;
    color: $text-light;

    &.is-rounded-input {
      left: 1.4rem;
    }

    &.has-icons {
      left: calc(2.75rem + 1px);

      ~ .helper-wrapper {
        left: calc(2.75rem + 1px);
      }
    }

    &.is-active {
      top: 0;
      font-size: $label-font-size-active;
      text-transform: uppercase;

      ~ .helper-wrapper {
        top: 0;

        .help {
          font-size: $label-font-size-active;
          text-transform: uppercase;
        }
      }
    }

    &.is-focused {
      color: $primary;
    }

    .asterisk {
      font-size: 0.8em;
      position: relative;
      top: -1px;
    }
  }

  &.quill-field {
    .label {
      position: static;
      margin-left: 1rem;
    }

    .control {
      border: 1px solid $ui-30;
      border-radius: $radius-large;
    }

    .quill {
      .ql-toolbar {
        border-radius: $radius-large $radius-large 0 0;
        border-bottom-width: 2px;
      }

      .ql-editor {
        padding: 1.25rem 1.5rem;
      }
    }
  }

  .control {
    &.has-icons-left,
    &.has-icons-right {
      .icon {
        z-index: 1;
      }
    }

    &.has-icons-left {
      .input {
        padding-left: 2.75rem;
      }
    }

    .input {
      font-size: $input-font-size;
      height: $input-height;
      transition: all $easing $speed;
      padding-top: $control-padding-vertical;
      padding-bottom: 0.3em;
      box-shadow: none !important;

      &.is-danger {
        border-color: #ffefee;
        background-color: #ffefee;

        ~ .icon {
          color: #dd0000 !important;
        }

        &:not(:disabled):not(.is-disabled) {
          &:focus,
          &.is-active,
          &.is-focused,
          &:hover,
          &.is-hover {
            background-color: #fffbfb;
            border-color: #f0e4e3;
          }
        }
      }

      &:not(:disabled):not(.is-disabled) {
        &:hover,
        &:focus,
        &.is-focused {
          background-color: $ui-10;
        }

        &:hover ~ .icon {
          color: $input-icon-active-color;
        }
      }

      &[type="color"] {
        &::-webkit-color-swatch {
          border-radius: $radius-small;
        }
        &::-mox-color-swatch {
          border-radius: $radius-small;
        }
      }
    }
  }

  .helper-wrapper {
    position: absolute;
    top: $label-position-top;
    left: $label-position-left;
    margin-top: 0;
    height: auto;
    z-index: 1;
    pointer-events: none;
    transition: all $speed $easing;
    line-height: 1.5rem;

    .help {
      margin-left: 0;
      font-size: $label-font-size;
      transition: all $speed $easing;
      white-space: nowrap;
    }
  }
}

.field {
  .helper-wrapper {
    height: 14px;
    margin-top: 0.2rem;

    .help {
      font-size: 11px;
      margin-left: $label-position-left;
      margin-top: 0;
    }
  }

  textarea {
    border-top: 24px solid transparent;
    border-bottom: 8px solid transparent;
    min-height: 100px;
    box-shadow: none !important;
    transition: all $easing $speed;

    &:not(:disabled):not(.is-disabled) {
      &:hover,
      &:focus,
      &.is-focused {
        background-color: $ui-10;
        border-color: $ui-10;
      }
    }
  }
}
