.pagination {
  margin-top: 1rem;
  margin-bottom: 5rem;
  margin-left: 0;
  margin-right: 0;

  .pagination-link {
    min-height: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .pagination-ellipsis {
    color: $ui-50;
  }
}
