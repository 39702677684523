.field.file-uploader {
  .label {
    position: static;
    padding-left: $label-position-left;
    margin-bottom: 0.75rem;
  }

  .file-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid $grey-light;
    border-radius: $radius;
    height: 3rem;
    transition: border 0.2s ease-in-out;

    &:hover {
      border-color: $blue;
    }

    .upload {
      color: $purple-light;
      background-color: transparent;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon {
        margin-right: 0.35em;
      }
    }

    .uploaded {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .content {
        position: relative;
        z-index: 2;
        width: calc(100% - 49px);
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 64px 0 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .overlay {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 48px;
        height: 100%;
        color: #fff;
        border-right: 1px solid $grey-light;
        cursor: pointer;
        background: transparent;

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }

      .fileName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pdfIcon {
        display: inline-block;
        margin-right: 8px;
      }

      .delete {
        max-height: none;
        max-width: none;
        width: auto;
        padding: 0 0.75rem;
        color: inherit;
        border: none;
        background: transparent;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.7;
        z-index: 2;
        width: 48px;
        height: 48px;
        transition: color 0.2s ease-in-out;

        &:hover {
          opacity: 1;
          color: $red;
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  input[type='file'] {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
  }
}
