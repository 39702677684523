.field.image-uploader {
  .label {
    position: static;
    padding-left: $label-position-left;
    margin-bottom: 0.75rem;
  }

  .image-container {
    width: 100%;
    padding-bottom: 57%;
    position: relative;
    overflow: hidden;
    border: 1px solid $grey-light;
    border-radius: $radius;
    min-height: 160px;

    .upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $purple-light;
      background-color: transparent;
    }

    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($sidebar-menu-item-background, 0.3);
        color: #fff;

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .delete {
          max-height: none;
          max-width: none;
          width: auto;
          padding: 0 0.75rem;
          border: none;
          background: transparent;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0.7;
          z-index: 2;
          font-size: inherit;

          &:hover {
            opacity: 1;
          }

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    .thumb-custom-data {
      padding: 20px;

      .overlay {
        background-color: transparent;
      }

      .icon {
        color: #aaa;
      }

      img {
        object-fit: contain;
      }
    }
  }

  input[type='file'] {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
  }
}
