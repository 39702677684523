.sidebar-container {
  display: flex;
  flex-flow: column;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: $sidebar-width;
  transition: 250ms ease-out;
  transition-property: width, background-color;
  background-color: #041840;

  .sidebar-logo-box {
    height: 80px;
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.04);
  }

  .logo {
    display: block;
    align-self: flex-start;
    width: 8.25rem;
    transition: margin $speed $easing;
    margin: 1rem 0 0 1.5rem;

    img {
      display: block;
    }
  }

  &.is-wide {
    width: $sidebar-wide-width;
    background-color: $blue;
  }

  .copyrights {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 0 1.5rem 1.875rem;
    color: $blue-light;
    font-size: 14px;
  }
}

.sidebar {
  $menu-link-border-radius: 24px;
  $menu-link-icon-margin-right: 0.5rem;

  flex: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .icon {
    position: relative;
    min-width: 32px;
    max-width: 32px;
  }

  .text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  .sidebar-menu {
    font-size: 15px;

    > li {
      margin-bottom: 0.25rem;
      display: flex;
      flex-flow: column;
      margin-left: auto;
      width: 15rem;
      border-radius: $menu-link-border-radius 0 0 $menu-link-border-radius;
      transition: background-color $speed $easing;

      &.is-active,
      &:hover {
        background-color: $sidebar-menu-item-background;
      }
    }
  }

  .menu-link {
    display: flex;
    align-items: center;
    transition: all $speed $easing;
    border-radius: $menu-link-border-radius 0 0 $menu-link-border-radius;
    color: #fff;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    font-weight: 400;

    .icon {
      color: #008ddd;
      transition: color $speed $easing;
    }

    .text {
      transition: color $speed $easing;
    }

    .icon,
    .image {
      margin-right: $menu-link-icon-margin-right;
    }

    &.is-active {
      font-weight: 700;
      .icon {
        color: #99f9ff;
      }

      .text {
        color: $blue-light;
      }
    }
  }

  .menu-link.sidebar-top-link {
    border-radius: 0px;
    margin-left: -15px;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.04);
    padding-left: 25px;
    height: 56px;
    color: #99f9ff;
    background-color: $sidebar-menu-item-background;
  }

  .menu-link.sidebar-bottom-link {
    border-radius: 0px;
    margin-left: -15px;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.04);
    padding: 0 25px;
    height: 56px;
    color: #99f9ff;

    & i {
      color: $purple-light;
      font-size: 32px;
      font-weight: 100;
    }

    &:hover {
      background-color: $sidebar-menu-item-background;
      cursor: pointer;
    }
  }

  .submenu {
    align-self: flex-end;
    width: calc(100% - 32px - 1rem);
    overflow: hidden;

    li {
      margin-bottom: 1rem;
    }

    a {
      color: #fff;

      &:hover,
      &.is-active {
        font-weight: 700;
      }
    }
  }
}
