.has-background-gradient-dark {
  background-image: $gradient-dark-default;
  background-repeat: repeat-x;
  background-size: cover;
  background-position: top;
}

.has-border-radius-small {
  border-radius: $radius-small !important;
}

.has-border-radius {
  border-radius: $radius !important;
}

.has-border-radius-large {
  border-radius: $radius-large !important;
}

.is-sticky {
  position: sticky !important;
  top: 0 !important;
}

.is-object-fit-cover {
  object-fit: cover !important;
}

.has-transition {
  transition: all $speed $easing;
}

.is-fullheight {
  flex: 1;
  display: flex;
  flex-flow: column;
}

hr {
  height: 1px;
}
