.product-editor {
  padding: 1.5rem;

  &-default-price {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 63px;
  }

  &-datepicker {
    margin-bottom: 0.75rem;
  }

  &-input-box {
    display: flex;
    margin-bottom: 0.75rem;

    .field.has-floating-label {
      flex: 1;

      &:first-child {
        margin-right: 0.75rem;
      }
    }
  }

  &-button-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h5 {
      margin-right: auto;
    }
  }
}
