.modal-background {
  background: $gradient-bright-default;
  opacity: 0.75;
}

.modal-card {
  box-shadow: $modal-card-shadow;
  overflow: auto;
}

.modal-card-body {
  &:last-child {
    border-radius: 0 0 $radius $radius;
  }

  .buttons {
    margin-top: $modal-card-body-padding;
  }

  fieldset {
    .buttons {
      margin-top: 0;
    }
  }

  + .modal-card-body {
    border-top: 1px solid $ui-30;
  }
}

.modal-card-title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-card-foot {
  justify-content: flex-end;
  overflow: hidden;
}
