.table-heading {
  &:hover {
    color: $purple-light;
    font-weight: 500;
  }

  .table-heading-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .table-sorting {
    width: 12px;
    height: 12px;
    position: relative;
    margin-left: 4px;

    .sort-asc,
    .sort-desc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
    }
  }
}
