.account-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  height: auto;
  background: linear-gradient(to bottom, transparent, rgba(6, 17, 60, 0.3) 25%, rgba(6, 17, 60, 0.45));
  padding: 1.4rem 1.6rem 1.4rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  overflow: hidden;
  transition: padding $speed $easing;
  z-index: 2;

  .delete {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem;
    padding: 0;
    border: none;
    background: transparent none;
    transition: right $speed $easing;
  }

  .menu-list {
    li {
      margin-bottom: 1rem;
    }

    .icon {
      margin-right: 1rem;
      transition: all $speed $easing;
    }

    .menu-link {
      white-space: nowrap;
      display: flex;
      align-items: center;
      color: #fff;
      opacity: 0.5;
      cursor: default;

      span:last-child {
        transition: opacity $speed $easing;
      }

      &:hover,
      &.is-active {
        background: transparent;
        font-weight: $weight-bold;
      }

      &[href],
      &.logout {
        cursor: pointer;
        opacity: 1;

        &:hover {
          color: #fff;

          .icon {
            color: #fff;
          }
        }
      }
    }
  }
}
