.card {
  border-radius: $radius-large;

  + .card {
    margin-top: 2rem;
  }

  .card-header-title {
    .tag {
      margin-left: 1rem;
    }
  }
}

#data {
  font-size: $input-font-size;
  min-height: 11em;
  margin-top: 1.45rem;
}
