.quill {
  max-height: none;
  min-height: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-flow: column;
  position: relative;

  .ql-toolbar,
  .ql-container {
    border: none;
  }

  .ql-toolbar {
    border-bottom: 1px solid rgba(black, 0.08);
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
    margin-left: 1px;
    min-height: 2.5rem;

    .ql-formats {
      margin-right: 0;
      border-right: 1px solid rgba(black, 0.08);
      padding: 0 0.5rem;
      display: flex;
      flex-flow: row;
      align-items: center;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: 0;
      }
    }

    button {
      padding: 0.5rem;
      width: auto;
      height: auto;
      float: none;
      color: $purple-light;

      &.ql-active,
      &:hover {
        color: $blue;
      }

      svg {
        float: none;
        height: 1.25rem;
        display: block;

        .ql-stroke {
          stroke: currentColor !important;
        }
      }
    }
  }

  .ql-editor {
    padding: 2rem;
    flex: 1;
    height: auto;
    font-family: $family-sans-serif;
  }
}
