.navbar-layout {
  box-shadow: inset 0 -1px 0 rgba(black, 0.08);
  min-height: 5rem;

  .navbar-item {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .button {
    &.is-rounded {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  .navbar-start {
    flex: 1;
  }

  .breadcrumb {
    li {
      a {
        display: block;
        max-width: 8.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .search-bar {
    padding-top: 0;
    padding-bottom: 0;
    background-color: $input-background-color;
    border-radius: $radius-rounded;
    padding-left: 0;
    min-width: 2.5rem;

    .search-input-wrapper {
      overflow: hidden;
      max-width: 500px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
    }

    .expanded-search-icon {
      min-width: 2.5rem !important;
      height: 2.5rem !important;
      display: inline-flex;
      margin: 0 !important;
      color: $primary;
    }

    .field {
      width: 100%;
      margin-bottom: 0 !important;

      .control {
        .input {
          height: 2.5rem;
          border-color: transparent !important;
          box-shadow: none !important;
          padding: 0 1rem 0 0;
        }
      }
    }
  }

  .content-tabs-wrapper {
    margin-bottom: 0 !important;

    .tag {
      margin-bottom: 0;
      margin-right: 0;

      &:first-child:not(:only-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;
      }

      &:last-child:not(:only-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(.is-primary) {
        &:hover {
          background-color: $grey-lighter;
        }
      }

      .delete {
        &:not(:hover) {
          color: inherit;
          background-color: transparent;
        }

        &:hover {
          color: $white;
        }

        &::before,
        &::after {
          background-color: currentColor;
        }
      }
    }

    .dropdown-trigger {
      .tag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
