fieldset {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  legend {
    display: block;
    width: 100%;
    font-size: $size-5;
    font-weight: 600;
    padding-bottom: 0.6rem;
    margin-bottom: 1.4rem;
    border-bottom: 1px solid $white-ter;
    color: $primary;
  }
}
