.tabs {
  ul {
    background-color: $white-ter;
  }

  li {
    font-size: 0.9375rem;

    &:not(.is-active) {
      a {
        border-bottom-color: transparent;
      }
    }

    &.is-active {
      background-color: $white;
    }
  }

  a {
    border-bottom-width: 2px;
  }
}
