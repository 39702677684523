.rselect-container {
  .rselect {
    &__control {
      background-color: $input-background-color;
      font-size: $input-font-size;
      border: 1px solid $input-border-color;
      border-radius: $input-radius;
      box-shadow: 0 0 0 transparent;
      cursor: pointer;

      &:hover,
      &--is-focused {
        border-color: $input-hover-border-color;
        background: $ui-10;
      }
    }

    &__value-container {
      padding: calc(0.976rem - 1px) calc(0.75rem - 3px);
      padding-bottom: calc(0.337rem - 1px);
      line-height: 1;
    }

    &__placeholder,
    &__single-value {
      top: 60%;
    }

    &__placeholder {
      color: $text-light;
    }

    &__menu {
      z-index: 999;
    }
  }
}
