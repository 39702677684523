.billing-and-plans {
  overflow: visible;

  .card {
    box-shadow: none;
    border: 1px solid $grey-lighter;
  }

  .subtitle {
    font-weight: 500;
    color: $purple-dark;
  }

  .info-card {
    .button.is-ghost {
      margin-left: $button-padding-horizontal * -1;
    }
  }

  .plan-card {
    .plan-name {
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .features {
      li {
        margin-bottom: 0.25rem;
      }

      .icon {
        color: $info;
        margin-right: 0.5rem;
      }
    }
  }

  .usage-card {
    .stat-column {
      .title {
        text-transform: uppercase;
      }
    }
  }
}
