.select {
  select {
    font-size: $input-font-size;
    height: $input-height;
    padding-bottom: calc(0.4rem - 1px);
    transition: all $easing $speed;
    box-shadow: none !important;

    &:hover,
    &.is-hover {
      background: $ui-10;
    }
  }

  &:not(.is-multiple):not(.is-loading) {
    &::after {
      z-index: 1;
    }
  }
}
