.login-page {
  display: flex;
  flex-flow: column;

  .navbar {
    background-color: transparent;

    a {
      color: $grey;

      &:hover {
        color: $grey-medium-dark;
      }
    }
  }

  .page-wrapper {
    width: 20rem;
    margin-left: 10rem;
    margin-top: 10rem;
  }

  .title {
    margin-bottom: 0.8rem;
    width: 600px;

    + p {
      margin-bottom: 1rem;
    }
  }

  .button.is-submit {
    font-size: 15px;
    height: 48px;

    .icon {
      margin-right: 1rem;
    }
  }

  .links {
    a {
      color: $grey;
      text-decoration: underline;
      font-size: 14px;

      &:hover {
        color: $grey;
        text-decoration: none;
      }
    }
  }

  .sign-in-alt {
    font-size: 15px;
    color: $purple-light;
  }

  .sign-in-alt-btns {
    .button {
      flex-direction: column;
      align-items: center;
      width: 5.5rem;
      height: 5rem;

      .icon {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 4px;
      }

      span {
        &:last-child {
          font-size: 12px;
          color: $purple-light;
        }
      }
    }
  }
}
