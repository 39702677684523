.image-gallery {
  &.is-inlined {
    padding: 0 !important;
  }

  .label {
    font-size: $label-font-size;
    color: $text-light;
    margin: 0 0 0 $label-position-left;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  .images-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }

  .thumb {
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 10rem;
    height: 10rem;
    position: relative;
    overflow: hidden;
    border-radius: $radius-large;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }

    .toolbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;

      .button {
        height: 1.5rem;

        .icon {
          margin: 0;
        }

        &.button-remove {
          padding: 0;
          width: 1.5rem;
        }

        &.button-set-main {
          transition: all $speed $easing;
          opacity: 0;
        }
      }
    }

    &:hover {
      .toolbox {
        .button.button-set-main {
          opacity: 1;
        }
      }
    }
  }

  .button-add {
    width: 10rem;
    height: 10rem;
  }

  input[type='file'] {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
  }
}
