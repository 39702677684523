.dashboard-notifications {
  margin-bottom: 44px;
}

.dashboard-card {
  .card {
    padding: 20px;
  }

  .card-header {
    display: flex;
    align-items: center;
  }

  .card-header-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    padding: 0;

    a {
      color: $text-strong;
    }

    .card-tag {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 8px;
      height: 16px;
      background: linear-gradient(45.43deg, #60c7cd 21.83%, #008ddd 85.97%);
      border-radius: 8px;
      margin: 0px 8px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      font-style: normal;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .card-info-details {
    color: $grey;
    font-weight: normal;
    font-size: 12px;
    padding-bottom: 10px;
  }

  .card-status-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 8px;
    background: $white-ter;
    border-radius: 8px;

    &-left {
      display: flex;
      align-items: center;

      a {
        font-weight: normal;
        font-size: 14px;
        color: $text-strong;
      }

      .card-status-bar-icon {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin: 0 8px;
        background-color: #eb5757;
      }
    }

    &-right {
      display: none;
      color: $grey;
      font-size: 14px;

      p {
        margin-right: 24px;
      }
    }
  }
}
