.editor-quill {
  &.quill .ql-container {
    min-height: 300px;
  }
}

.editor-html {
  min-height: 357px !important;
}

.settings-icon {
  float: right;
  font-size: 14pt;
  padding-top: 8px;

  i {
    color: #ccc;
  }

  i:hover {
    color: $text;
    cursor: pointer;
  }
}
