html,
body,
#root {
  min-height: 100vh;
}

body {
  background-color: #fff;
}

// Fixes sidebar breaking when Sweetalert2 is visible
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: visible !important;
}
