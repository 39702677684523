.page-block-toolbar {
  padding-top: 2.5rem;
  flex: 0;
  background-color: $white;
  position: sticky;
  top: 2.875rem;
  margin-left: 1px;
  z-index: 3;
}

.container.is-fluid.page-blocks-wrapper {
  padding-top: 0;
}

.page-block {
  box-shadow: none;
  border: 1px solid $ui-30;
  transition: border-color $speed $easing;

  &.is-active {
    border-color: $primary;
  }

  .card-header {
    box-shadow: none;
  }
}
