.field.color-picker {
  .label {
    position: static;
    padding-left: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .color-picker-component {
    border: 1px solid $grey-light;
  }
}
