@import '../variables';

// Quill editor styling
$quill-editor-content-width: 730px;
$quill-image-offset: 190px;
$quill-image-max-width: $quill-editor-content-width + $quill-image-offset * 2;
$quill-floating-image-spacing: 95px;
$quill-paragraph-spacing: 42px;

// Media queries
$quill-mediaQuery-lg: $page-spacing + $sidebar-width + $quill-image-max-width;

// Hide overflow images
.ql-container {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-flow: column;
  height: auto;
}
