.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #f6f6ff;
  font-size: 14px;

  .delete {
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    transform: translateY(-2px);
  }

  &.is-primary {
    background: $blue;
    margin-bottom: 8px;

    .delete {
      background-color: $blue;

      &::before,
      &::after {
        background-color: $blue-light;
      }

      &::before {
        width: 70%;
      }

      &::after {
        height: 70%;
      }
    }
  }

  .notification-info {
    a {
      color: $blue;
    }
  }

  &-white {
    border: 1px solid $white-ter;
  }
}
