.main-image {
  img {
    border-radius: $radius;
  }

  .button.is-white {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    border-color: transparent !important;
    border-top-right-radius: 0;
    color: $red;
  }

  &-label {
    input {
      position: absolute;
      left: -9999px;
      width: 0;
      height: 0;
      appearance: none;
      opacity: 0;
    }
  }
}
