table.table {
  th {
    font-weight: 400;
    font-size: $size-7;
    border-width: 0;
  }

  td {
    vertical-align: middle;
    min-height: 64px;
    font-size: 15px;

    a {
      color: $purple-light;

      &:hover {
        color: $purple-dark;
      }
    }
  }

  tr {
    &.is-main-row {
      td {
        border-top: $table-cell-border;
        border-bottom: 0;
      }
    }

    &.is-sub-row {
      td {
        border-bottom: 0;
      }
    }
  }
}

.table-container {
  max-width: #{1120px + $gap * 2};
  margin: 0 auto;
  padding: $gap $gap 0;
}
