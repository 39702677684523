.tree-view-child {
  display: inline-block;
  width: 0.5rem;
  height: 0.5px;
  position: relative;
  vertical-align: middle;
  background-color: $ui-60;
  margin-right: 0.25rem;
  margin-left: 0.7rem;

  &::before {
    content: '';
    height: 28px;
    bottom: 100%;
    left: 0;
    background-color: inherit;
    position: absolute;
    width: 0.5px;
  }
}
